<template>
  <div>
    <div class="m-2">
      <b-row class="justify-content-end">
        <div class="mr-1">
          <b-button
            variant="primary"
            @click="openSuggestionForm()"
          >
            Add Suggestion
          </b-button>
        </div>
      </b-row>
    </div>
    <b-card no-body>
      <!--table top-->
      <!-- <suggestion-form /> -->
      <div class="m-2">
        <b-row align-h="between">
          <b-col
            cols="12"
            md="6"
            class="mb-1"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
              @input="debounceSearch(meta.offset = 0, getRequestedQuery)"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-1"
          >
            <v-select
              v-model="status"
              :options="[{value: 'true', label: 'Active'}, {value: 'false', label: 'Inactive'}]"
              :reduce="option => option.value"
              :clearable="true"
              class="status-filter-select"
              placeholder="Select Status"
              @input="getRequestedQuery()"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-1"
          >
            <v-select
              v-model="region"
              :options="[{value: 'BD', label: 'Bangladesh'}, {value: 'PK', label: 'Pakistan'}]"
              :reduce="option => option.value"
              :clearable="true"
              class="status-filter-select"
              placeholder="Select Region"
              @input="getRequestedQuery()"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-col>
        </b-row>
      </div>
      <b-table
        id="tourTokenizer"
        :items="suggestionList"
        :fields="tableColumns"
        :busy="isTableLoading"
        primary-key="id"
        empty-text="No matching records found"
        responsive
        show-empty
        class="position-relative"
      >

        <template #table-busy>
          <div class="text-center text-primary m-2">
            <b-spinner class="align-middle" />
            <strong> Loading...</strong>
          </div>
        </template>

        <template #cell(is_active)="data">
          <b-badge
            pill
            :variant="data.item.is_active ? 'light-success' : 'light-danger'"
          >{{ data.item.is_active ? 'Active' : 'Inactive' }}</b-badge>
        </template>

        <template #cell(three_dot)="data">
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            class="action-menu"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- Edit -->
            <b-dropdown-item @click="openSuggestionForm(data.item)">
              <feather-icon icon="Edit2Icon" />
              <span
                class="align-middle ml-50"
              >
                Edit
              </span>
            </b-dropdown-item>
            <!-- Status -->
            <b-dropdown-item
              v-b-modal.suggestion-status
              @click="getSuggestionData(data.item)"
            >
              <feather-icon :icon="data.item.is_active ? 'SlashIcon' : 'CircleIcon'" />
              <span
                class="align-middle ml-50"
              >
                {{ data.item.is_active ? 'Deactivate' : 'Activate' }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <TablePagination
        ref="pagination"
        :limit="meta.limit"
        :list-data="suggestionList"
        :total="meta.total"
        @paginate="paginate"
      />
      <SuggestionForm
        :suggestion-info="suggestionInfo"
        @getSuggestionDetails="getSuggestionList"
        @resetData="resetData"
      />
    </b-card>
    <b-modal
      id="suggestion-status"
      ref="suggestion-status"
      ok-only
      ok-variant="primary"
      :modal-class="suggestionData.is_active ? 'modal-danger' : 'modal-primary'"
      centered
      title="Suggestion List"
    >
      <b-card-text>
        Are you sure you want to {{ suggestionData.is_active ? 'inactivate' : 'activate' }}?
      </b-card-text>
      <template #modal-footer>
        <b-button
          :variant="suggestionData.is_active ? 'danger' : 'primary'"
          :disabled="isTableLoading"
          @click="changeSuggestionStatus()"
        >
          <b-spinner
            v-if="isTableLoading"
            small
          />
          Confirm
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'
import calendar from '@/mixins/calendar'
import SuggestionForm from './SuggestionForm.vue'
import TablePagination from '@/components/common/TablePagination.vue'

export default {
  name: 'SuggestionList',
  components: {
    SuggestionForm,
    TablePagination,
  },
  mixins: [helper, calendar],
  data() {
    return {
      suggestionList: [],
      isTableLoading: false,
      regions: {
        PK: 'Pakistan',
        BD: 'Bangladesh',
      },
      meta: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      // Table Handlers
      tableColumns: [
        { key: 'three_dot', label: '', thClass: 'sm' },
        { key: 'title', thClass: 'lg' },
        { key: 'type', thClass: 'sm', formatter: value => value.split('_')[0] },
        {
          key: 'tours',
          label: 'No. Of Tours',
          thClass: 'sm',
          formatter: value => value.length,
        },
        { key: 'order_id', label: 'Order', thClass: 'sm' },
        {
          key: 'region', label: 'Region', thClass: 'md', formatter: value => this.regions[value] || 'N/A',
        },
        {
          key: 'is_active',
          label: 'status',
          thClass: 'sm',
        },
      ],
      requestType: '',
      suggestionInfo: {},
      searchQuery: '',
      suggestionData: {},
      region: '',
      status: null,
    }
  },
  computed: {
    filterQuery() {
      const search = this.searchQuery ? `&keyword=${this.searchQuery}` : ''
      const region = this.region ? `&region=${this.region}` : ''
      const status = this.status !== null ? `&is_active=${this.status}` : ''
      return `${search}${region}${status}`
    },
  },
  mounted() {
    this.getSuggestionList()
  },
  methods: {
    changeSuggestionStatus() {
      this.isTableLoading = true
      api.updateData(`tour/admin_services/auto_suggestion_update/${this.suggestionData.id}/`, true,
        { is_active: !this.suggestionData.is_active })
        .then(response => {
          if (response.data.status) {
            this.getSuggestionList()
            this.$refs['suggestion-status'].hide()
            this.notificationMessage('success', 'CheckIcon', 'Success', 'Your request has been successfully processed!')
          } else {
            this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          }
        }).catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
        }).finally(() => {
          this.isTableLoading = false
        })
    },
    getSuggestionList() {
      this.isTableLoading = true
      api.getData(`tour/admin_services/auto_suggestion_list/?offset=${this.meta.offset}${this.filterQuery}`, true)
        .then(response => {
          if (response.data.status) {
            this.suggestionList = response.data.result.results
            this.meta = response.data.result.meta
          } else {
            this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          }
        }).catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
        }).finally(() => {
          this.isTableLoading = false
        })
    },
    paginate(page) {
      this.meta.offset = (page - 1) * 10
      this.getSuggestionList()
    },
    getRequestedQuery() {
      this.meta.offset = 0
      this.$refs.pagination.resetCurrentPage()
      this.getSuggestionList()
    },
    getSuggestionData(suggestionData) {
      this.suggestionData = suggestionData
    },
    openSuggestionForm(data) {
      if (data) {
        this.suggestionInfo = { ...data }
        this.suggestionInfo.type = this.suggestionInfo.type === 'LOCATION_TYPE' ? 1 : 0
      } else {
        this.suggestionInfo = {}
      }
      this.requestType = 'suggestion-form'
      this.$root.$emit('bv::toggle::collapse', 'suggestion-details-sidebar')
    },
    resetData() {
      this.requestType = ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>
