import { render, staticRenderFns } from "./SuggestionForm.vue?vue&type=template&id=f8735c1c&scoped=true&"
import script from "./SuggestionForm.vue?vue&type=script&lang=js&"
export * from "./SuggestionForm.vue?vue&type=script&lang=js&"
import style0 from "./SuggestionForm.vue?vue&type=style&index=0&id=f8735c1c&lang=scss&scoped=true&"
import style1 from "./SuggestionForm.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./SuggestionForm.vue?vue&type=style&index=2&lang=scss&"
import style3 from "./SuggestionForm.vue?vue&type=style&index=3&id=f8735c1c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8735c1c",
  null
  
)

export default component.exports